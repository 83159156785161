import React from "react";

import {Modal} from "react-bootstrap";
import Button from "components/Button";

export const ModalApp = ({show, handleModal, isMap = false, children, label = ''}) => {
    return  <Modal
        show={show}
        onHide={ handleModal }
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className={`rounded-0 ${isMap && 'p-0'} `}
    >
        <Modal.Body className={`text-center p-0 m-0`}>
            {children}

            <div className="mx-3 position-relative">
                <Button onClick={handleModal} className="btn-close-white btn-link">Fechar</Button>
            </div>

        </Modal.Body>
    </Modal>
}
