import React, { useEffect, useRef, useState} from "react";

import Button from 'components/Button'
import {FixedRating} from "components/Rating";
import {CarouselHotels} from "components/Carousel";
import SearchBarHotels from "components/Safra/SearchBarHotels";
import {useNavigate, Navigate} from "react-router-dom";

import {Container} from 'react-bootstrap'
import {
    HotelInformation,
    LocationMap,
    DivAbout,
    Paragraph,
    BoxRoom,
    Rooms,
    RoomContent,
    Rules,
} from "./styles";

import {AiOutlineCheckCircle} from "react-icons/ai";
import {IoPerson} from "react-icons/io5";

import {formatCurrency} from "../../../utils/functions";
import {LoaderSearchImage} from "../../../components/Loader";
import {useApp} from "../../../contexts/AppContext";
import {changeSelectedRoomHotelAction} from "../../../contexts/reducers/hotels/actions";

import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import {isChannel} from "../../../utils";
import {useGenialApp} from "../../../contexts/GenialAppContext";

export default function HotelPage() {
    const [loading, setLoading] = useState(false)
    const [info, setInfo] = useState(true)
   // const [open, setOpen] = useState(false)
    const [infoRoom, setInfoRoom] = useState(null)

    const [checkedRoom, setCheckedRoom] = useState()

    const { hotelState, hotelDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { hotel, offersHotel, travellers } = hotelState

    const scroll = useRef(null)

    const executeScroll = () => {
        scroll.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    },[])

    window.parent.postMessage(1900, "*");

    const navigate = useNavigate()

    function handleContinue() {
        const element = document.getElementById('root')
        element.scrollIntoView({block: "start", behavior: "smooth"})
        navigate('/hotel-resume/')
    }

    function handleShowRooms(index) {
        setInfoRoom(index)
    }

    function handleInfo() {
        setInfo(!info)
    }

    const handleReserve = (room, index) => {
        hotelDispatch(changeSelectedRoomHotelAction(room))
        setCheckedRoom(index)

        let element = document.getElementById('divRegras');
        element.scrollIntoView({block: "start", behavior: "smooth"})
    }

    const Room = () => {

        const totalPeople = travellers?.length;

        const Icon = () => {
            let icons = [];
            for (let i = 1; i <= totalPeople; i++) {
                icons.push(<IoPerson key={i}/>)
            }
            return icons
        }

        return (
            <Rooms>
                { hotel.rooms.map((item, index) => {

                    const price = item?.amount?.BRL
                    const points = item?.amount?.ENVISION
                    const externalProperties = JSON.parse(item?.externalProperties)

                    return (
                        <RoomContent key={index}>
                            <p className="label">{item.name}</p>
                            <span className="price-icon">
                                Preço para <Icon/>
                            </span>

                            <div className="div-info">
                                { externalProperties["Refundable"] === "False" || externalProperties["Refundable"] === undefined
                                    && <p className="info-1">Sem Reeembolso</p> }

                                { externalProperties["Refundable"] === "True"
                                    && <p className="info-1">Com Reembolso</p> }

                                { item?.board?.description.toUpperCase() === "BREAKFAST"
                                    ? <p className="info-2"> Café da manhã incluso</p>
                                    : <p className="info-2"> Sem Café da manhã</p>
                                }
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="price">
                                        { offersHotel === "cash" && <p> { formatCurrency(price) }</p> }
                                        { offersHotel === "points" && <p> { parseFloat(points).toLocaleString() } <span>Pts</span> </p> }
                                        <p className="span">taxas inclusas</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <Button width="146px" bgDisabled={`${checkedRoom !== index ? "#707070" : "#89A7AA"}`} onClick={() => handleReserve(item, index)}>
                                        { checkedRoom === index ? 'Selecionado' : 'Selecionar' }
                                    </Button>
                                </div>
                            </div>

                            { infoRoom === index && (
                                <div className="divText">
                                    <p className="title">Sobre o quarto</p>
                                    <span className="paragraph">
                                       { item?.description ? item?.description : '-' }
                                    </span>
                                </div>
                            )}

                           {!item.description &&
                                infoRoom === index  ?
                                    <span onClick={() => setInfoRoom(null)} className="ver-mais">
                                        <span>Ver Menos <FiChevronUp/></span>
                                    </span>
                               :
                                   <span onClick={() => handleShowRooms(index)} className="ver-mais">
                                        <span>Ver Mais <FiChevronDown/></span>
                                    </span>
                           }
                        </RoomContent>
                        )
                    }
                )}
            </Rooms>
        )
    }

    const handleSearchHotels = () => {
        navigate('/hotels-search-result')
    }

    if (loading) return <LoaderSearchImage />

    if (!loading && !hotel.name) return <Navigate to="/hotels-search-result" />

    return (
        <>
            <SearchBarHotels
                info={info}
                handleInfo={handleInfo}
                searchHotels={handleSearchHotels}
            />

            { hotel.name &&
                <>
                    <Container className="container-page px-4">
                        <HotelInformation>
                            <div className="hotel">
                                <h3>{hotel.name}</h3>
                                <FixedRating value={hotel.stars}/>
                            </div>

                            <span className="map">
                                <LocationMap/>
                                {hotel.address.street}, {hotel.address.city}
                            </span>

                        </HotelInformation>

                        <CarouselHotels images={hotel.images}/>

                        <div className="mb-3" >
                            <Button type="button" onClick={executeScroll}>Ver disponibilidade</Button>
                        </div>

                        <DivAbout>
                            <p className="sobre-hotel">Sobre o Hotel</p>
                            <Paragraph>
                                <div dangerouslySetInnerHTML={{__html : hotel.description }} />
                            </Paragraph>
                            <p className="quarto mb-0">Quartos</p>
                            <Paragraph>Encontre a melhor acomodação neste hotel</Paragraph>
                        </DivAbout>
                    </Container>

                    <BoxRoom>
                        <nav className="px-4">
                            <div className="nav nav-tabs border-bottom-0 d-flex gap-1" id="nav-tab" role="tablist">
                                <button
                                    className="nav-link active button-tab"
                                    id={`room-1`}
                                    data-bs-toggle="tab"
                                    data-bs-target={`#nav-room-1`}
                                    type="button"
                                    role="tab"
                                    aria-controls={`nav-room-1`}
                                    aria-selected="true"
                                >
                                    Quarto <AiOutlineCheckCircle/>
                                </button>
                            </div>
                        </nav>
                        <div ref={scroll} style={{marginTop: "90px", position: "absolute"}}/>
                        <div className="tab-content" id="nav-tabContent" style={{maxHeight: "600px", overflow: "auto"}}>
                            <div

                                className={`tab-pane fade show active`}
                                id={`nav-room-1`}
                                role="tabpanel"
                                aria-labelledby={`nav-room-1-tab`}
                            >
                                <Room/>
                            </div>

                        </div>
                    </BoxRoom>

                    <Rules>
                        <p className="rule">Regras do Estabelecimento</p>
                        <div className="div-check mb-3" id="divRegras">
                            <p>
                                Check-in
                                <span>
                                    16:00
                                </span>
                            </p>
                            <p>
                                Check-out
                                <span>
                                    10:00
                                </span>
                            </p>
                        </div>
                        <p className="rule">Regras de Cancelamento</p>
                        <div className="div-check mb-3">
                            <ul>
                                <li>
                                    Para o reembolso, quando aplicável, serão observadas as regras do estabelecimento e
                                    o cancelamento deverá ser solicitado com antecedência mínima de 07 dias da data
                                    do check-in.
                                </li>
                                <li>
                                    Em caso de check-in não realizado, ou alteração e cancelamento de reserva com
                                    antecedência menor do que a exigida pela política do estabelecimento, ou mesmo após
                                    o check-in, você estará sujeito a multa de até 100% do valor da reserva.
                                </li>
                            </ul>
                        </div>

                        <Button id="btnContinuar"
                            onClick={handleContinue}
                            bgDisabled={`${!hotelState.room.name ? "#707070" : "#40545E"}`}
                            disabled={!hotelState.room.name}
                        >
                            Continuar
                        </Button>
                    </Rules>
                </>
            }
        </>
    );
}
