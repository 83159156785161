import React, {useState} from "react";

import {FaFilter, FaMapMarkerAlt} from 'react-icons/fa';

import {isChannel, meal_plan} from 'utils'

import { ModalApp } from "components/Modal";
import Checkbox from "components/CheckboxCustom";
import Button from 'components/Button';
import {ContainerContentFilter, DivStars} from "./styles";
import {FixedRating} from "../../Rating";
import CurrencyInput from "../../../utils/InputCurrency";
import {useApp} from "../../../contexts/AppContext";
import {useGenialApp} from "../../../contexts/GenialAppContext";

const starsCheckbox = ['1', '2', '3', '4', '5'];

export default function FilterHotels({filter, order, openMap, showMap, applyFilter, handleApplyOrder}) {
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState(filter.filterParameters.price.max)
    const [stars, setStars] = useState(filter?.filterParameters.hotelStars
        ? filter?.filterParameters?.hotelStars
        : []
    )
    const [breakfast, setBreakfast] = useState('breakfast')
    const { hotelState } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { offersHotel } = hotelState

    const handleModal = () => {
        setOpen(!open)

        if (open === true) {
            const elemento = document.getElementById('filtrarEstrelas');
            elemento.scrollIntoView({behavior: "smooth"})
        }
    };

    const doFilter = () => {
        let currency = 'BRL'
        if (offersHotel === 'points') {
            currency = 'POINTS'
        }

        const filter = {
            "filterParameters": {
                "price": {
                    "min": 20,
                    "max": price,
                    "currency": currency
                },
                "hotelStars": stars,
                "hotelBoards": [
                    breakfast
                ]
            }
        }

        applyFilter(filter)
        setOpen(false)
    }

    const handleChangeOrder = (order) => {
        handleApplyOrder(order)
    }

    const handlePriceSearch = (value) => {
        setPrice(value)
    }

    const handleChangeStars = (e) => {
        const { value, checked } = e.target;

        const newStartFilter = [...stars]

        if (checked) {
            setStars( state => [...state, value]);
        } else {
            setStars( newStartFilter.filter((e) => e !== value));
        }
    }

    return (
        <div className="row align-items-end">
            <div className="col-7 d-flex gap-2">
                <Button outline={true} width="100px" onClick={handleModal}>
                    <FaFilter/> Filtrar
                </Button>
            </div>

            <div className="col-5">
                <select
                    className="form-select form-select-custom border-custom float-end"
                    defaultValue={order}

                    onChange={(e) => handleChangeOrder(e.target.value)}
                >
                    <option value="">Ordenar por</option>
                    <option value="az">A-Z</option>
                    <option value="za">Z-A</option>
                    <option value="asc_price">Menor preço</option>
                    <option value="desc_price">Maior Preço</option>
                    <option value="stars">Estrelas</option>
                </select>
            </div>

            <ModalApp show={open} handleModal={handleModal}>
                <ContainerContentFilter className="px-4">

                    <p className="label">Valor</p>

                    <div className="input-group">
                        <label className="d-flex flex-grow-1 label-custom">Valor de diária até</label>

                        <CurrencyInput
                            type="text"
                            className="form-control form-control-custom border-custom text-end"
                            value={price}
                            onChange={(e) => handlePriceSearch(e.target.value)}
                        />
                    </div>

                    <p className="label" id="filtrarEstrelas">Estrelas</p>

                    <DivStars>
                        {starsCheckbox.map((item) =>
                            <Checkbox
                                key={item}
                                name={item}
                                value={item}
                                checked={stars?.find(star => star === item)}
                                label={<FixedRating value={Number(item)} />}
                                onChange={(e) => { handleChangeStars(e) }}
                            />
                        )}
                    </DivStars>

                    <p className="label">Plano de Refeição</p>

                    <div className="d-grid gap-2 text-start">
                        { meal_plan.map((item, index) =>
                            <div key={index}>
                                <Checkbox
                                    type="radio"
                                    name="breakfast"
                                    checked={ breakfast === item.value}
                                    label={item.label}
                                    value={item.value}
                                    onChange={(e) => setBreakfast(e.target.value)}
                                />
                            </div>
                        )}
                    </div>

                    <div className="my-4">
                        <Button width="200px" onClick={() => doFilter()}>Filtrar</Button>
                    </div>
                </ContainerContentFilter>
            </ModalApp>
        </div>
    );
}
